<template>
  <HeadlessTransitionRoot appear :show="isOpen" as="template">
    <HeadlessDialog as="div" class="relative z-[999]" @close="close">
      <HeadlessTransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-dark/80"></div>
      </HeadlessTransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 md:p-base text-center"
        >
          <HeadlessTransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <HeadlessDialogPanel
              class="w-full max-w-3xl transform overflow-hidden bg-white rounded p-base text-left transition-all"
            >
              <div class="fk-prose mb-base-1/2">
                <h2>
                  <template v-if="!openApplication">
                    {{
                      $t("general.applyForVacancy", {
                        vacancyTitle: vacancyTitle,
                      })
                    }}
                  </template>
                  <template v-else>{{
                    $t("general.openApplication")
                  }}</template>
                </h2>
                <div v-if="companyName" class="font-bold">
                  {{ companyName }}
                </div>
              </div>

              <ApplicationCrud :vacancy-id="vacancyId" :from-applicant="true" />

              <!-- Close -->
              <div
                class="absolute top-1 right-1 text-dark transition-transform hover:rotate-90 w-14 aspect-square grid place-items-center cursor-pointer"
                @click="close"
              >
                <svg
                  class="aspect-square w-3 stroke-current stroke-[2px]"
                  aria-hidden="true"
                >
                  <line
                    x1="0%"
                    y1="50%"
                    x2="100%"
                    y2="50%"
                    class="origin-center rotate-45"
                  />
                  <line
                    x1="0%"
                    y1="50%"
                    x2="100%"
                    y2="50%"
                    class="origin-center -rotate-45"
                  />
                </svg>
              </div>
            </HeadlessDialogPanel>
          </HeadlessTransitionChild>
        </div>
      </div>
    </HeadlessDialog>
  </HeadlessTransitionRoot>
</template>

<script setup lang="ts">
const { isOpen, close } = useWerkgelukModal("apply");

defineProps<{
  vacancyId?: number | undefined;
  vacancyTitle?: string;
  companyId?: number;
  companyName?: string;
  openApplication?: boolean;
}>();
</script>
