<template>
  <div class="bg-white p-base rounded fk-prose">
    <h2 v-if="firstName || infix || lastName">
      {{
        $t("general.contactFullName", {
          fullName: formatFullName(firstName, infix, lastName),
        })
      }}
    </h2>
    <a
      v-if="phone"
      :href="`tel:${phone}`"
      class="group grid grid-cols-[1rem_1fr] gap-2 items-center !mb-0 mt-2"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="w-full h-auto"
        viewBox="0 0 512 512"
      >
        <path
          d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"
        />
      </svg>
      <span class="group-hover:underline">{{ phone }}</span>
    </a>
    <a
      v-if="email"
      :href="`mailto:${email}`"
      class="group grid grid-cols-[1rem_1fr] gap-2 items-center"
      :title="email"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="w-full h-auto"
        viewBox="0 0 512 512"
      >
        <path
          d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"
        />
      </svg>
      <div class="group-hover:underline break-words max-w-72">{{ email }}</div>
    </a>
    <div class="flex flex-wrap items-start gap-2 mt-4">
      <a
        v-if="openApplication"
        class="!m-0 btn"
        @click.prevent="openApplyModal"
        >{{ $t("general.applyForOpenApplication") }}</a
      >
      {{}}
      <a
        v-if="email"
        :href="`mailto:${email}?subject=${emailSubject}`"
        class="!m-0 btn"
        :class="{ 'btn-secondary': openApplication }"
        >{{ $t("general.askYourQuestion") }}</a
      >
    </div>
  </div>
</template>

<script setup lang="ts">
const { open: openApplyModal } = useWerkgelukModal("apply");

withDefaults(
  defineProps<{
    openApplication: boolean;
    firstName?: string;
    infix?: string;
    lastName?: string;
    phone?: string;
    email?: string;
    emailSubject?: string;
  }>(),
  {
    firstName: "",
    infix: "",
    lastName: "",
    phone: "",
    email: "",
    emailSubject: "",
  },
);
</script>
